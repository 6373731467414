<template>
	<div class="vulnerability">
		<div class="mx-25 search">
			<a-col :span="24" class="text-right">
				<a-input-search v-if="queryStorageId" :placeholder="$t('Vulnerabilities.EnterStorageSpace')" class="v-search"
					v-model="vulnerabilityQuery.storageId" @search="vulnerabilityTableSearch()" />
				<a-input-search v-if="queryRepositoryId" :placeholder="$t('Vulnerabilities.EnterWarehouse')" class="v-search"
					v-model="vulnerabilityQuery.repositoryId" @search="vulnerabilityTableSearch()" />
				<a-input-search :placeholder="$t('Vulnerabilities.EnterVulnerabilityNumber')" class="v-search" v-model="vulnerabilityQuery.vulnerabilityUuid"
					@search="vulnerabilityTableSearch()" />
				<a-select default-value="lucy" :placeholder="$t('Vulnerabilities.TimeRangeSearch')" class="v-search"
					v-model="vulnerabilityQuery.dateSearchType" :allowClear="true" @change="vulnerabilityTableSearch()">
					<a-select-option v-for="(i, index) in i18nDateSearchTypes" :key="index" :value="i.value">
						{{ i.label }}
					</a-select-option>
				</a-select>
			</a-col>
		</div>
    <a-table rowKey="nativeId" :columns="vulnerabilityColumns" :data-source="vulnerabilityData"
      @change="handleVulnerabilityTableChange" :loading="vulnerabilityTableLoading"
             :scroll="{ x: true }"
             :pagination="{ pageSize: vulnerabilityQuery.limit, current: vulnerabilityQuery.page, total: vulnerabilityQuery.total, showLessItems: true }" :row-key="(r, i) => i.toString()">
      <template slot="cvssV2Severity" slot-scope="cvssV2Severity">
        <div class="table-avatar-info" v-if="cvssV2Severity">
          <a-avatar v-if="['CRITICAL', 'MEDIUM', 'HIGH', 'LOW'].indexOf(cvssV2Severity) != -1" :size="24"
                    :src="'images/folib/' + cvssV2Severity.toLowerCase() + '.svg'" />
          <a-avatar v-else shape="circle" :size="24">{{ cvssV2Severity.slice(0, 1) }}</a-avatar>
          <div class="avatar-info">
            <p class="mb-0 text-dark">{{
                cvssV2Severity === 'CRITICAL' ? $t('Vulnerabilities.Seriously') : cvssV2Severity === 'MEDIUM' ? $t('Vulnerabilities.MediumRisk') : cvssV2Severity === 'HIGH' ? $t('Vulnerabilities.HighRisk') : cvssV2Severity === 'LOW' ? $t('Vulnerabilities.LowRisk') : cvssV2Severity
              }}
            </p>
          </div>
        </div>
      </template>
      <template slot="cvssV3Severity" slot-scope="cvssV3Severity">
        <div class="table-avatar-info" v-if="cvssV3Severity">
          <a-avatar v-if="['CRITICAL', 'MEDIUM', 'HIGH', 'LOW'].indexOf(cvssV3Severity) != -1" :size="24"
                    :src="'images/folib/' + cvssV3Severity.toLowerCase() + '.svg'" />
          <a-avatar v-else shape="circle" :size="24">{{ cvssV3Severity.slice(0, 1) }}</a-avatar>
          <div class="avatar-info">
            <p class="mb-0 text-dark">{{
                cvssV3Severity === 'CRITICAL' ? $t('Vulnerabilities.Seriously') : cvssV3Severity === 'MEDIUM' ? $t('Vulnerabilities.MediumRisk') : cvssV3Severity === 'HIGH' ? $t('Vulnerabilities.HighRisk') : cvssV3Severity === 'LOW' ? $t('Vulnerabilities.LowRisk') : cvssV3Severity
              }}
            </p>
          </div>
        </div>
      </template>
      <template slot="expandedRowRender" slot-scope="record">
        <a-tag color="#87d068" class="description-title">{{ $t('Vulnerabilities.VulnerabilityDescription') }}</a-tag>
        <a-textarea class="description" :autoSize="true" :read-only="true" v-model="record.description" />
      </template>
      <template slot="highestSeverityText" slot-scope="highestSeverityText">
        <div class="table-avatar-info">
          <a-avatar v-if="['CRITICAL', 'MEDIUM', 'HIGH', 'LOW'].indexOf(highestSeverityText) != -1" :size="24"
                    :src="'images/folib/' + highestSeverityText.toLowerCase() + '.svg'" />
          <a-avatar v-else shape="circle" :size="24">{{ highestSeverityText.slice(0, 1) }}</a-avatar>
          <div class="avatar-info">
            <p class="mb-0 text-dark">{{
                highestSeverityText === 'CRITICAL' ? $t('Vulnerabilities.Seriously') : highestSeverityText === 'MEDIUM' ? $t('Vulnerabilities.MediumRisk') : highestSeverityText === 'HIGH' ? $t('Vulnerabilities.HighRisk') : highestSeverityText === 'LOW' ? $t('Vulnerabilities.LowRisk') : highestSeverityText
              }}
            </p>
          </div>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ $t('Vulnerabilities.Download') }}
          </template>
          <a-button class="o-btn" :loading="downLoading" @click="downExcel(record)">
            <img src="images/folib/download.svg" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ $t('Vulnerabilities.Atlas') }}
          </template>
          <a-button class="o-btn o-graph" @click="showGraph(record)">
            <img src="images/folib/graph.svg" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ $t('Vulnerabilities.TheWhiteList') }}
          </template>
          <a-popconfirm :title="$t('Vulnerabilities.SureAddedWhitelist')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="addWhite(record)"
                        v-if="vulnerabilityLevel === 1 && operatorEnabled && record.white == 0 && record.black == 0">
            <div class="o-btn">
              <img src="images/folib/white.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureRemovedWhitelist')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="removeWhite(record)"
                        v-if="vulnerabilityLevel === 1 && operatorEnabled && record.white == 1">
            <div class="o-btn o-rm">
              <img src="images/folib/white.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureAddedWhitelist')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="addRepositoryWhites(record)"
                        v-if="vulnerabilityLevel === 2 && operatorEnabled && record.white == 0 && record.black == 0">
            <div class="o-btn">
              <img src="images/folib/white.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureRemovedWhitelist')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="removeRepositoryWhites(record)"
                        v-if="vulnerabilityLevel === 2 && operatorEnabled && record.white == 1">
            <div class="o-btn o-rm">
              <img src="images/folib/white.svg" />
            </div>
          </a-popconfirm>
        </a-tooltip>
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ $t('Vulnerabilities.Blacklist') }}
          </template>
          <a-popconfirm :title="$t('Vulnerabilities.SureAddedBlacklisted')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="addBlack(record)"
                        v-if="vulnerabilityLevel === 1 && operatorEnabled && record.white == 0 && record.black == 0">
            <div class="o-btn o-black">
              <img src="images/folib/black.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureRemovedBlacklisted')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="removeBlack(record)"
                        v-if="vulnerabilityLevel === 1 && operatorEnabled && record.black == 1">
            <div class="o-btn o-rm">
              <img src="images/folib/black.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureAddedBlacklisted')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="addRepositoryBlacks(record)"
                        v-if="vulnerabilityLevel === 2 && operatorEnabled && record.white == 0 && record.black == 0">
            <div class="o-btn o-black">
              <img src="images/folib/black.svg" />
            </div>
          </a-popconfirm>
          <a-popconfirm :title="$t('Vulnerabilities.SureRemovedBlacklisted')" okType="danger" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')"
                        @confirm="removeRepositoryBlacks(record)"
                        v-if="vulnerabilityLevel === 2 && operatorEnabled && record.black == 1">
            <div class="o-btn o-rm">
              <img src="images/folib/black.svg" />
            </div>
          </a-popconfirm>
        </a-tooltip>
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ $t('Vulnerabilities.BugArtifacts') }}
          </template>
          <div class="o-btn o-bug" @click="searchArtifact(record)">
            <a-icon
                type="bug"
                theme="filled"
                :style="{ fontSize: '18px' }"
              />
          </div>
        </a-tooltip>
      </template>
    </a-table>
    <a-modal v-model="showGraphModal" :title="$t('Vulnerabilities.Atlas')" :ok-text="$t('Vulnerabilities.BeSure')" :cancel-text="$t('Vulnerabilities.Cancel')" @ok="() => { showGraphModal = false }"
             width="80vw" id="graphModal" class="graph-modal" centered>
      <div class="vulnerability-g6" id="vulnerabilityG6" ref="vulnerabilityG6"></div>
    </a-modal>
    <a-drawer placement="right" width="65%" :title="artifactQuery.vulnerabilityUuid + ' ' + $t('Vulnerabilities.BugArtifacts')"
      :visible="artifactsVisible" @close="closeUserDialog" class="bug-artifacts">
      <!-- <div class="mx-auto m-50" style="max-width: 1000px;"> -->
        <a-card :bordered="false">
          <div class="mx-25 search">
            <a-col :span="24" class="text-right">
              <a-input-search
              placeholder="请输入制品名称查询"
              class="v-search"
              v-model="artifactQuery.artifactName"
              @search="searchArtifact()"
              />
            </a-col>
          </div>
          <a-table rowKey="nativeId" :columns="i18nArtifactsColumns" :data-source="artifactData" @change="handleArtifactsTableChange" :loading="artifactsLoading"
              :scroll="{ x: true }"
              :pagination="{ pageSize: artifactQuery.limit, current: artifactQuery.page, total: artifactQuery.total, showLessItems: true }" :row-key="(r, i) => i.toString()" @expandedRowsChange="artifactExpandedRowsChange($event)">
              <template slot="expandedRowRender" slot-scope="record">
                <a-row :gutter="[24]" v-if="record.columns">
                    <a-col :span="12" v-for="(item, index) in record.columns" :key="index">
                      <a-card :bordered="false" class="header-solid mt-20">
                        <a-table :columns="item" :data-source="record.data[index]" :pagination="false">
                    </a-table>
                  </a-card>
                  </a-col>                 
                </a-row>
              </template>
          </a-table>
        </a-card>
      <!-- </div> -->
    </a-drawer>
	</div>
</template>

<script>

import {
  vulnerabilityPage, vulnerabilityExportExcel, addVulnerabilitiesWhite, addVulnerabilitiesBlack, removeVulnerabilitiesWhite, removeVulnerabilitiesBlack, vulnerabilityGraph,
  addRepositoryWhites, removeRepositoryWhites, addRepositoryBlacks, removeRepositoryBlacks, getLibraryFilter, getArtifactsPage
} from "@/api/folib";
import store from '@/store';
import G6 from '@antv/g6';
import { hasRole, isAdmin, hasPermission } from "@/utils/permission";

export default {
  props: {
    vulnerabilityColumns: {
      type: Array,
      default: () => [],
    },
    queryStorageId: {
      type: Boolean,
      default: true,
    },
    queryRepositoryId: {
      type: Boolean,
      default: true,
    },
    storageId: {
      type: String,
      default: '',
    },
    repositoryId: {
      type: String,
      default: '',
    },
    repositoryType: {
      type: String,
      default: '',
    },
    vulnerabilityLevel: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.userInfo = store.state.user
    this.getVulnerabilityPage(this.storageId, this.repositoryId)
    this.operatorEnabled = (isAdmin() || this.storageAdmin === this.$store.state.user.name) && "group" !== this.repositoryType
    if (this.storageId) {
      this.getStorage(this.storageId)
    }
    console.log("this.repositoryType", this.repositoryType)
  },
  watch: {
    storageId: function (newval, oldVal) {
      if (newval) {
        this.getStorage(newval)
      }
    },
  },
  data() {
    return {
      dateSearchTypes: [
        {
          label: "最近一周",
          i18nKey: 'Vulnerabilities.InTheLastWeek',
          value: 1,
        },
        {
          label: "最近一个月",
          i18nKey: 'Vulnerabilities.InTheLastMonth',
          value: 2,
        },
        {
          label: "最近三个月",
          i18nKey: 'Vulnerabilities.InTheLastThreeMonths',
          value: 3,
        },
        {
          label: "最近半年",
          i18nKey: 'Vulnerabilities.InTheLastSixMonths',
          value: 4,
        },
      ],
      vulnerabilityQuery: {
        page: 1,
        limit: 10,
        total: 0,
        storageId: '',
        repositoryId: '',
        dateSearchType: undefined,
        vulnerabilityUuid: '',
        source: this.vulnerabilityLevel,
      },
      vulnerabilityData: [],
      vulnerabilityTableLoading: false,
      downLoading: false,
      showGraphModal: false,
      graph: null,
      graphData: {},
      storageAdmin: '',
      operatorEnabled: false,
      artifactData: [],
      artifactQuery: {
        page: 1,
        limit: 10,
        total: 0,
        storageId: '',
        repositoryId: '',
        vulnerabilityUuid: '',
        artifactName: undefined,
      },
      artifactsLoading: false,
      artifactsVisible: false,
      artifactsColumns: [
        {
          title: "存储空间",
          i18nKey: 'Vulnerabilities.StorageSpace',
          dataIndex: "storageId",
        },
        {
          title: "所属仓库",
          i18nKey: 'Vulnerabilities.OwnedWarehouse',
          dataIndex: "repositoryId",
        },
        {
          title: "制品路径",
          i18nKey: 'Vulnerabilities.ProductPath',
          dataIndex: "artifactPath",
          scopedSlots: { customRender: "artifactPath" },
        },
        {
          title: "下载次数",
          i18nKey: 'Vulnerabilities.DownloadCount',
          dataIndex: "downloadCount",
          scopedSlots: { customRender: "downloadCount" },
        },
      ]
    }
  },
  computed: {
    i18nDateSearchTypes() {
      return this.dateSearchTypes.map(column => {
        if (column.i18nKey) {
          column.label = this.$t(column.i18nKey);
        }
        return column;
      });
    },
    i18nArtifactsColumns() {
      return this.artifactsColumns.map(column => {
        if (column.i18nKey) {
          column.title = this.$t(column.i18nKey);
        }
        return column;
      });
    }
  },
  methods: {
    getVulnerabilityPage(storageId, repositoryId) {
      if (storageId) {
        this.vulnerabilityQuery.storageId = storageId
      }
      if (repositoryId) {
        this.vulnerabilityQuery.repositoryId = repositoryId
      }
      this.vulnerabilityTableLoading = true
      vulnerabilityPage(this.vulnerabilityQuery).then(res => {
        this.vulnerabilityData = res.data.rows
        this.vulnerabilityQuery.total = res.data.total
      }).finally(() => {
        this.vulnerabilityTableLoading = false
      })
    },
    handleVulnerabilityTableChange(pagination) {
      if (pagination) {
        this.vulnerabilityQuery.page = pagination.current
      }
      this.getVulnerabilityPage()
    },
    vulnerabilityTableSearch() {
      this.vulnerabilityQuery.page = 1
      this.handleVulnerabilityTableChange()
    },
    downExcel(record) {
      this.downLoading = true
      vulnerabilityExportExcel({ vulnerabilityUuid: record.uuid, storageId: this.vulnerabilityQuery.storageId, repositoryId: this.vulnerabilityQuery.repositoryId }).then(res => {
        if (!res) {
          return
        }
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let fileName = this.$t('Vulnerabilities.Vulnerabilities') + record.uuid + this.$t('Vulnerabilities.VulnerabilityScope')
        if (window.navigator.msSaveOrOpenBlob) {
          //兼容IE10
          navigator.msSaveBlob(blob, fileName)
        } else {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.downLoading = false
      })
    },
    showGraph(record) {
      if (this.graph) {
        //如果存在画布，销毁画布，重新渲染
        this.graph.destroy()
      }
      this.showGraphModal = true
      this.initGraph(record.uuid)
    },
    vulnerabilityGraph(uuid) {
      vulnerabilityGraph({ uuid: uuid, storageId: this.vulnerabilityQuery.storageId, repositoryId: this.vulnerabilityQuery.repositoryId }).then(res => {
        this.graphData = res
        this.configGraph()
      });
    },
    configGraph() {
      //组件props
      const props = {
        //数据
        data: this.graphData,
        config: {
          padding: [20, 50],
          defaultLevel: 3,
          defaultZoom: 0.8,
          //缩放和拖拽
          modes: { default: ['zoom-canvas', 'drag-canvas'] },
        },
      }
      //宽高
      const container = document.getElementById('vulnerabilityG6');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 500;
      //颜色
      const colors = {
        B: '#5B8FF9',
        R: '#F46649',
        Y: '#EEBC20',
        G: '#5BD8A6',
        DI: '#A7A7A7',
      }
      // 默认配置
      const defaultConfig = {
        width,
        height,
        modes: {
          //缩放和拖拽
          default: ['zoom-canvas', 'drag-canvas'],
        },
        fitView: true,
        animate: true,
        defaultNode: {
          type: 'flow-rect',
        },
        defaultEdge: {
          type: 'cubic-horizontal',
          style: {
            stroke: '#CED4D9',
          },
        },
        layout: {
          type: 'indented',
          direction: 'LR',
          dropCap: false,
          indent: 300,
          getHeight: () => {
            return 60;
          },
        },
      }
      // 自定义节点、边
      const registerFn = () => {
        /**
         * 自定义节点
         */
        G6.registerNode(
            'flow-rect',
            {
              shapeType: 'flow-rect',
              draw(cfg, group) {
                const {
                  name = '',
                  variableName,
                  variableValue,
                  variableUp = '',
                  label,
                  collapsed,
                  currency,
                  status,
                  rate,
                } = cfg;
                const grey = '#CED4D9';
                // 逻辑不应该在这里判断
                const rectConfig = {
                  width: 202,
                  height: 60,
                  lineWidth: 1,
                  fontSize: 12,
                  fill: '#fff',
                  radius: 4,
                  stroke: grey,
                  opacity: 1,
                };

                /**
                 * format the string
                 * @param {string} str The origin string
                 * @param {number} maxWidth max width
                 * @param {number} fontSize font size
                 * @return {string} the processed result
                 */
                const fittingString = (str, maxWidth, fontSize) => {
                  let currentWidth = 0;
                  let res = str;
                  const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                  str.split('').forEach((letter, i) => {
                    if (currentWidth > maxWidth) return;
                    if (pattern.test(letter)) {
                      // Chinese charactors
                      currentWidth += fontSize;
                    } else {
                      // get the width of single letter according to the fontSize
                      currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth) {
                      res = `${str.substr(0, i)}\n${str.substr(i)}`;
                    }
                  });
                  return res;
                };

                const nodeOrigin = {
                  x: -rectConfig.width / 2,
                  y: -rectConfig.height / 2,
                };

                const textConfig = {
                  textAlign: 'left',
                  textBaseline: 'bottom',
                };

                const rect = group.addShape('rect', {
                  attrs: {
                    x: nodeOrigin.x,
                    y: nodeOrigin.y,
                    ...rectConfig,
                  },
                });

                const rectBBox = rect.getBBox();

                // label title
                group.addShape('text', {
                  attrs: {
                    ...textConfig,
                    x: 12 + nodeOrigin.x,
                    y: 20 + nodeOrigin.y,
                    text: name.length > 28 ? name.substr(0, 28) + '...' : name,
                    fontSize: 12,
                    opacity: 0.85,
                    fill: '#000',
                    cursor: 'pointer',
                  },
                  name: 'name-shape',
                });

                // price
                const price = group.addShape('text', {
                  attrs: {
                    ...textConfig,
                    x: 12 + nodeOrigin.x,
                    y: rectBBox.maxY - 12,
                    text: fittingString(label, 170, 11),
                    fontSize: 10,
                    fill: '#000',
                    opacity: 0.85,
                  },
                });

                // label currency
                group.addShape('text', {
                  attrs: {
                    ...textConfig,
                    x: price.getBBox().maxX + 5,
                    y: rectBBox.maxY - 12,
                    text: currency,
                    fontSize: 8,
                    fill: '#000',
                    opacity: 0.75,
                  },
                });

                // percentage
                const percentText = group.addShape('text', {
                  attrs: {
                    ...textConfig,
                    x: rectBBox.maxX - 8,
                    y: rectBBox.maxY - 12,
                    text: variableValue,
                    fontSize: 12,
                    textAlign: 'right',
                    fill: colors[status],
                  },
                });

                // percentage triangle
                let symbol = variableUp ? 'triangle' : 'triangle-down'
                const triangle = group.addShape('marker', {
                  attrs: {
                    ...textConfig,
                    x: percentText.getBBox().minX - 10,
                    y: rectBBox.maxY - 12 - 6,
                    symbol,
                    r: 6,
                    fill: colors[status],
                  },
                });

                // variable name
                group.addShape('text', {
                  attrs: {
                    ...textConfig,
                    x: triangle.getBBox().minX - 4,
                    y: rectBBox.maxY - 12,
                    text: variableName ? ('建议修复版本 ' + variableName) : '',
                    fontSize: 6,
                    textAlign: 'right',
                    fill: '#000',
                    opacity: 0.45,
                  },
                });

                // bottom line background
                const bottomBackRect = group.addShape('rect', {
                  attrs: {
                    x: nodeOrigin.x,
                    y: rectBBox.maxY - 4,
                    width: rectConfig.width,
                    height: 4,
                    radius: [0, 0, rectConfig.radius, rectConfig.radius],
                    fill: '#E0DFE3',
                  },
                });

                let color = 'B'
                if (name === '漏洞编号') {
                  color = 'R'
                }
                // bottom percent
                const bottomRect = group.addShape('rect', {
                  attrs: {
                    x: nodeOrigin.x,
                    y: rectBBox.maxY - 4,
                    width: rate * rectBBox.width,
                    height: 4,
                    radius: [0, 0, 0, rectConfig.radius],
                    fill: colors[color],
                  },
                });

                // collapse rect
                if (cfg.children && cfg.children.length) {
                  group.addShape('rect', {
                    attrs: {
                      x: rectConfig.width / 2 - 8,
                      y: -8,
                      width: 16,
                      height: 16,
                      stroke: 'rgba(0, 0, 0, 0.25)',
                      cursor: 'pointer',
                      fill: '#fff',
                    },
                    name: 'collapse-back',
                    modelId: cfg.id,
                  });

                  // collpase text
                  group.addShape('text', {
                    attrs: {
                      x: rectConfig.width / 2,
                      y: -1,
                      textAlign: 'center',
                      textBaseline: 'middle',
                      text: collapsed ? '+' : '-',
                      fontSize: 16,
                      cursor: 'pointer',
                      fill: 'rgba(0, 0, 0, 0.25)',
                    },
                    name: 'collapse-text',
                    modelId: cfg.id,
                  });
                }

                this.drawLinkPoints(cfg, group);
                return rect;
              },
              update(cfg, item) {
                const group = item.getContainer();
                this.updateLinkPoints(cfg, group);
              },
              setState(name, value, item) {
                if (name === 'collapse') {
                  const group = item.getContainer();
                  const collapseText = group.find((e) => e.get('name') === 'collapse-text');
                  if (collapseText) {
                    if (!value) {
                      collapseText.attr({
                        text: '-',
                      });
                    } else {
                      collapseText.attr({
                        text: '+',
                      });
                    }
                  }
                }
              },
              getAnchorPoints() {
                return [
                  [0, 0.5],
                  [1, 0.5],
                ];
              },
            },
            'rect',
        );

        G6.registerEdge(
            'flow-cubic',
            {
              getControlPoints(cfg) {
                let controlPoints = cfg.controlPoints; // 指定controlPoints
                if (!controlPoints || !controlPoints.length) {
                  const { startPoint, endPoint, sourceNode, targetNode } = cfg;
                  const { x: startX, y: startY, coefficientX, coefficientY } = sourceNode
                      ? sourceNode.getModel()
                      : startPoint;
                  const { x: endX, y: endY } = targetNode ? targetNode.getModel() : endPoint;
                  let curveStart = (endX - startX) * coefficientX;
                  let curveEnd = (endY - startY) * coefficientY;
                  curveStart = curveStart > 40 ? 40 : curveStart;
                  curveEnd = curveEnd < -30 ? curveEnd : -30;
                  controlPoints = [
                    { x: startPoint.x + curveStart, y: startPoint.y },
                    { x: endPoint.x + curveEnd, y: endPoint.y },
                  ];
                }
                return controlPoints;
              },
              getPath(points) {
                const path = [];
                path.push(['M', points[0].x, points[0].y]);
                path.push([
                  'C',
                  points[1].x,
                  points[1].y,
                  points[2].x,
                  points[2].y,
                  points[3].x,
                  points[3].y,
                ]);
                return path;
              },
            },
            'single-line',
        );
      }
      registerFn()
      const { data } = props;
      const initGraph = (data) => {
        if (!data) {
          return;
        }
        const { onInit, config } = props;
        const tooltip = new G6.Tooltip({
          // offsetX and offsetY include the padding of the parent container
          offsetX: 20,
          offsetY: 30,
          // the types of items that allow the tooltip show up
          // 允许出现 tooltip 的 item 类型
          itemTypes: ['node'],
          // custom the tooltip's content
          // 自定义 tooltip 内容
          getContent: (e) => {
            const outDiv = document.createElement('div');
            //outDiv.style.padding = '0px 0px 20px 0px';
            const nodeName = e.item.getModel().name;
            let formatedNodeName = '';
            for (let i = 0; i < nodeName.length; i++) {
              formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
              if (i !== 0 && i % 20 === 0) formatedNodeName = `${formatedNodeName}<br/>`;
            }
            outDiv.innerHTML = `${formatedNodeName}`;
            return outDiv;
          },
          shouldBegin: (e) => {
            if (e.target.get('name') === 'name-shape') return true;
            return false;
          },
        });
        this.graph = new G6.TreeGraph({
          container: 'vulnerabilityG6',
          ...defaultConfig,
          ...config,
          plugins: [tooltip],
        });
        if (typeof onInit === 'function') {
          onInit(this.graph);
        }
        this.graph.data(data);
        this.graph.render();
        this.graph.zoom(config.defaultZoom || 1);

        const handleCollapse = (e) => {
          const target = e.target;
          const id = target.get('modelId');
          const item = this.graph.findById(id);
          const nodeModel = item.getModel();
          nodeModel.collapsed = !nodeModel.collapsed;
          this.graph.layout();
          this.graph.setItemState(item, 'collapse', nodeModel.collapsed);
        };
        this.graph.on('collapse-text:click', (e) => {
          handleCollapse(e);
        });
        this.graph.on('collapse-back:click', (e) => {
          handleCollapse(e);
        });
      }
      initGraph(data);
      if (typeof window !== 'undefined')
        window.onresize = () => {
          if (!this.graph || this.graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          this.graph.changeSize(container.scrollWidth, container.scrollHeight);
        }
    },
    initGraph(uuid) {
      this.vulnerabilityGraph(uuid)
    },
    addWhite(record) {
      addVulnerabilitiesWhite({ white: record.uuid }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.AddWhitelistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.handleVulnerabilityTableChange()
      })
    },
    addBlack(record) {
      addVulnerabilitiesBlack({ black: record.uuid }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.AddBlacklistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.handleVulnerabilityTableChange()
      })
    },
    removeWhite(record) {
      removeVulnerabilitiesWhite({ white: record.uuid }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.RemovedWhitelistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.handleVulnerabilityTableChange()
      })
    },
    removeBlack(record) {
      removeVulnerabilitiesBlack({ black: record.uuid }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.RemovedBlacklistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.handleVulnerabilityTableChange()
      })
    },
    successMsg(message) {
      if (!message) {
        message = this.$t('Vulnerabilities.OperateSuccess')
      }
      this.$notification["success"]({
        message: message,
      })
    },
    addRepositoryWhites(record) {
      addRepositoryWhites(this.storageId, this.repositoryId, { vulnerabilityWhites: [record.uuid] }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.AddWhitelistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.$emit('repositoryVulnerabilityStatistics')
        this.handleVulnerabilityTableChange()
      })
    },
    addRepositoryBlacks(record) {
      addRepositoryBlacks(this.storageId, this.repositoryId, { vulnerabilityBlacks: [record.uuid] }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.AddBlacklistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.$emit('repositoryVulnerabilityStatistics')
        this.handleVulnerabilityTableChange()
      })
    },
    removeRepositoryWhites(record) {
      removeRepositoryWhites(this.storageId, this.repositoryId, { vulnerabilityWhites: [record.uuid] }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.RemovedWhitelistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.$emit('repositoryVulnerabilityStatistics')
        this.handleVulnerabilityTableChange()
      })
    },
    removeRepositoryBlacks(record) {
      removeRepositoryBlacks(this.storageId, this.repositoryId, { vulnerabilityBlacks: [record.uuid] }).then(res => {
        this.successMsg(record.uuid + this.$t('Vulnerabilities.RemovedBlacklistSuccess'))
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.$emit('repositoryVulnerabilityStatistics')
        this.handleVulnerabilityTableChange()
      })
    },
    getStorage(id) {
      getLibraryFilter(id).then(response => {
        this.storageAdmin = response.admin
        this.operatorEnabled = (isAdmin() || this.storageAdmin === this.$store.state.user.name) && "group" !== this.repositoryType
      })
    },
    getArtifactsPage(record) {
      if (record) {
        this.artifactQuery.vulnerabilityUuid = record.uuid
      }
      if (this.storageId) {
        this.artifactQuery.storageId = this.storageId
      }
      if (this.repositoryId) {
        this.artifactQuery.repositoryId = this.repositoryId
      }
      this.artifactsLoading = true
      getArtifactsPage(this.artifactQuery).then(res => {
        this.artifactData = []
        this.artifactQuery.total = 0
        if (res && res.data) {
          this.artifactQuery.total = res.data.total
          if (res.data.rows) {
            this.artifactData = res.data.rows
          }
        }
      }).catch((err) => {
        this.$notification["error"]({
          message: err.response.data.error,
        })
      }).finally(() => {
        this.artifactsLoading = false
      })
    },
    closeUserDialog() {
      this.artifactData = []
      this.artifactQuery = {
        page: 1,
        limit: 10,
        total: 0,
        storageId: '',
        repositoryId: '',
        vulnerabilityUuid: '',
        artifactName: undefined,
      }
      this.artifactsVisible = false
    },
    searchArtifact(record) {
      this.artifactsVisible = true
      this.artifactQuery.page = 1
      this.getArtifactsPage(record)
    },
    handleArtifactsTableChange(pagination) {
      if (pagination) {
        this.artifactQuery.page = pagination.current
      }
      this.getArtifactsPage(null)
    },
    artifactExpandedRowsChange(event) {
    }
  }
}
</script>

<style lang="scss" scoped>
$md: 768px;

.vulnerability::v-deep {

  .table-avatar-info .ant-avatar {
    margin-right: 8px;
  }

  .description {
    width: 92%;
    border: none;
    box-shadow: none;
    resize: none;
    background: #fbfbfb;
    vertical-align: middle;
  }

  .description-title {
    vertical-align: middle;
  }

  .o-btn {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background-color: #1890FF;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  .o-btn img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .o-graph {
    background-color: #7adcfc;
  }

  .o-black {
    background-color: #f58080
  }

  .o-rm {
    background-color: #d81e06
  }

  .o-bug {
    color: white;
    cursor: pointer;
  }

  .v-search {
    max-width: 200px;
    width: 170px;
    min-width: 150px;
    margin-left: 5px;
    margin-bottom: 8px;
  }

  .v-search-div {
    display: inline-block;
  }

  .mx-25 .ant-row-flex {
    flex-wrap: wrap;
  }

  .search{
    height: 50px;
  }

}

.graph-modal {
  height: 90vh;
}

.vulnerability-g6 {
  height: 75vh;
}

.g6-component-tooltip {
  background-color: rgba(0, 0, 0, 0.65);
  padding: 10px;
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
  width: fit-content;
  color: #fff;
  border-radius: 4px;
}

.bug-artifacts .search{
  height: 50px;
}

.bug-artifacts .v-search {
  max-width: 200px;
  width: 170px;
  min-width: 150px;
  margin-left: 5px;
  margin-bottom: 8px;
}
</style>
